export const environment = {
  production: false,
  panel: "kAdmin_",
  domain: "admin.kanhaexch.com",
  baseUrl: "https://api.kanhaexch.com",

  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  userList: [
      { role_id: 1, id: 'admin', name: 'Admin', cIds: [], lIds: [] },
      { role_id: 2, id: 'super_master', name: 'Super Master', cIds: [1, 2], lIds: [1, 2] },
      { role_id: 3, id: 'master', name: 'Master', cIds: [1, 2], lIds: [1, 2] },
      { role_id: 4, id: 'client', name: 'Client', cIds: [1, 2, 3], lIds: [1, 2, 3] },
  ],
  hidePartenerShips: [9994],
  host: {
      domain: '',
      name: '',
      ext: '',
      theme: '',
      email: '',
      pusher: {
          key: '',
          cluster: ''
      },
      isMarketSetting: true,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'pdm-admin.pages.dev',
          name: 'pdmclient.pages',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'kanhaexch.com',
          name: 'kanhaexch',
          dName: 'admin.kanhaexch.com',
          ext: '.com',
          theme: 'orange-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'chotu99.com',
          name: 'chotu99',
          dName: 'admin.chotu99.com',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'my11circle.club',
          name: 'my11circle',
          dName: 'admin.my11circle.club',
          ext: '.club',
          theme: 'darkcyan-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'my11gamer.club',
          name: 'my11gamer',
          dName: 'admin.my11gamer.club',
          ext: '.club',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'pdm999.com',
          name: 'pdm999',
          dName: 'admin.pdm999.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'chotuexch.bet',
          name: 'chotuexch',
          dName: 'admin.chotuexch.bet',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'worldmarckets.in',
          name: 'worldmarckets',
          dName: 'admin.worldmarckets.in',
          ext: '.in',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'ignite999.com',
          name: 'ignite999',
          dName: 'admin.ignite999.com',
          ext: '.com',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: '7starexch.com',
          name: '7starexch',
          dName: 'admin.7starexch.com',
          ext: '.com',
          theme: 'darkcyan-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'rudra999.com',
          name: 'rudra999',
          dName: 'admin.rudra999.com',
          ext: '.com',
          theme: 'darkcyan-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'cheetah999.com',
          name: 'cheetah999',
          dName: 'admin.cheetah999.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'junoonexchange.com',
          name: 'junoonexchange',
          dName: 'admin.junoonexchange.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'betwayexch.com',
          name: 'betwayexch',
          dName: 'admin.betwayexch.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'dynamo.bet',
          name: 'dynamo',
          dName: 'admin.dynamo.bet',
          ext: '.bet',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'bigbull69.bet',
          name: 'bigbull69',
          dName: 'admin.bigbull69.bet',
          ext: '.bet',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'raddyanna.bet',
          name: 'raddyanna',
          dName: 'admin.raddyanna.bet',
          ext: '.bet',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'raddyanna.com',
          name: 'raddyanna',
          dName: 'admin.raddyanna.com',
          ext: '.com',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'mybet999.com',
          name: 'mybet999',
          dName: 'admin.mybet999.com',
          ext: '.com',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'khandada9.com',
          name: 'khandada9',
          dName: 'admin.khandada9.com',
          ext: '.com',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'runexch365.run',
          name: 'runexch365',
          dName: 'admin.runexch365.run',
          ext: '.run',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'royalbets999.com',
          name: 'royalbets999',
          dName: 'admin.royalbets999.com',
          ext: '.com',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'gopal.bet',
          name: 'gopal',
          dName: 'admin.gopal.bet',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'shyam.bet',
          name: 'shyam',
          dName: 'admin.shyam.bet',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'omexch.bet',
          name: 'omexch',
          dName: 'admin.omexch.bet',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'gs999.online',
          name: 'gs999',
          dName: 'admin.gs999.online',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'eagle999.co',
          name: 'eagle999',
          dName: 'admin.eagle999.co',
          ext: '.co',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'kesar365.com',
          name: 'kesar365',
          dName: 'admin.kesar365.com',
          ext: '.co',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'ambaniexch.com',
          name: 'ambaniexch',
          dName: 'admin.ambaniexch.com',
          ext: '.co',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'batballs247.com',
          name: 'batballs247',
          dName: 'admin.batballs247.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'jazbaexch.com',
          name: 'jazbaexch',
          dName: 'admin.jazbaexch.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'rkanna.club',
          name: 'rkanna',
          dName: 'admin.rkanna.club',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'bigbro18.com',
          name: 'bigbro18',
          dName: 'admin.bigbro18.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'crex999.com',
          name: 'crex999',
          dName: 'admin.crex999.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          isMarketSetting: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
  ],
};
